import {
  AppstoreOutlined,
  HomeOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Col, Layout, Menu, Row } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext } from 'react'
import { UserContext } from '../contexts/UserContext'
import { validSubscriber } from '../lib/helpers'
import { AuthApi } from '../services/api/auth'
import { setGAUser } from '../services/analytics'
import * as S from './Header.styled'
import Search from './Search'
import IconSave from './Icons/IconSave'
import { removeAuthorizationHeader } from '../utils/token'

const MenuItem = Menu.Item

export interface HeaderProps {
  logo?: 'footprint' | 'greenbook' | 'calculator'
  loggedIn: boolean
  hideSearchBox?: boolean
  hideWelcomeMenuIcon?: boolean
}

const Header: FunctionComponent<HeaderProps> = ({
  logo,
  loggedIn,
  hideSearchBox = false,
  hideWelcomeMenuIcon = false,
}) => {
  const { setUser } = useContext(UserContext)
  const { user } = useContext(UserContext)
  const router = useRouter()

  const logout = async () => {
    await AuthApi.logout()
    removeAuthorizationHeader()
    setGAUser({ username: '' })
    setUser(null)
    router.push('/login')
  }

  return (
    <Layout.Header className='fixed-header'>
      <Row align='middle'>
        <Col span={6}>
          <Link
            href={
              logo === 'footprint'
                ? '/welcome/'
                : logo === 'calculator'
                ? '/projects'
                : '/'
            }
            passHref
          >
            <a>
              <S.Logo
                src={
                  logo === 'footprint'
                    ? '/GB-Logo-Light.png'
                    : logo === 'calculator'
                    ? '/CL-Logo.png'
                    : '/GB-Logo-Light.png'
                }
                width={logo === 'footprint' ? 180 : 150}
              />
            </a>
          </Link>
        </Col>
        <Col span={18}>
          <Row justify='end' gutter={32}>
            {loggedIn ? (
              <>
                {!hideSearchBox && (
                  <Col className='hidden-mobile ant-row-middle ant-row'>
                    <Search />
                  </Col>
                )}
                <Col>
                  <Menu
                    theme='dark'
                    mode='horizontal'
                    className='menu-icons'
                    selectable={false}
                  >
                    {logo === 'calculator' && (
                      <MenuItem
                        key='calc_help'
                        icon={<QuestionCircleOutlined style={S.CustomIconStyle} />}
                        title='Calculator Help'
                        onClick={() => router.push('/help?module=calculator')}
                      />
                    )}
                    {(!logo || logo == 'greenbook') && (
                      <MenuItem
                        key='gb_help'
                        icon={<QuestionCircleOutlined style={S.CustomIconStyle} />}
                        title='Greenbook Help'
                        onClick={() => router.push('/help?module=greenbook')}
                      />
                    )}
                    {user && validSubscriber(user) && (
                      <MenuItem
                        key='home'
                        icon={<HomeOutlined style={S.CustomIconStyle} />}
                        title='Home'
                        onClick={() => router.push('/welcome/')}
                      />
                    )}
                    {!hideWelcomeMenuIcon && (
                      <>
                        <MenuItem
                          key='dashboard'
                          icon={<AppstoreOutlined style={S.CustomIconStyle} />}
                          title='Dashboard'
                          onClick={() => router.push('/')}
                        />
                        <MenuItem
                          key='favourites'
                          icon={<IconSave style={S.CustomIconStyle} />}
                          title='Favourites'
                          onClick={() => router.push('/favourites/')}
                        />
                      </>
                    )}
                    <MenuItem
                      key='settings'
                      icon={<SettingOutlined style={S.CustomIconStyle} />}
                      title='Settings'
                      onClick={() => router.push('/settings/')}
                    />
                    <MenuItem
                      key='logout'
                      icon={<LogoutOutlined style={S.CustomIconStyle} />}
                      title='Log out'
                      onClick={logout}
                    />
                  </Menu>
                </Col>
              </>
            ) : null}
          </Row>
        </Col>
        {loggedIn && !hideSearchBox && (
          <Col span={24} className='visible-mobile'>
            <Search style={{ width: '80%', margin: '5px 0' }} />
          </Col>
        )}
      </Row>
    </Layout.Header>
  )
}

Header.defaultProps = {
  loggedIn: false,
}

export default Header
